import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// PY04776-590
// Control the panel when the calendar is Open/Close
export const DATA_RANGE_PICKER_OPENED = 'dataRangePicker:open'
const DATA_RANGE_PICKER_OPENED_EVENT = new Event(DATA_RANGE_PICKER_OPENED)

export const DATA_RANGE_PICKER_CLOSED = 'dataRangePicker:close'
const DATA_RANGE_PICKER_CLOSED_EVENT = new Event(DATA_RANGE_PICKER_CLOSED)

dayjs.extend(customParseFormat)

function toggleInputsHandler(e) {
  e.preventDefault()
  var currentWrapper = this.closest('.wrapper')
  var locatorCode = currentWrapper.querySelector('.locator-code')
  var toggleInputs = currentWrapper.querySelector('.toggle-inputs')
  locatorCode.classList.toggle('hidden')
  toggleInputs.classList.toggle('hidden')
}

function init($wrapper) {
  if (!$wrapper.length) return

  const $form = $wrapper.find('form')

  // Refresh validation to ignore hidden inputs
  IB.forms.initValidation($form, true)

  // Datepicker init
  if ($wrapper.find('.single-datepicker-cnt').length) {
    const language = document.documentElement.getAttribute('data-js-lang')
    const date_format = $('html').attr('data-date-format')
    const start_of_week = $('html').attr('data-start-of-week')

    $wrapper
      .find('.single-datepicker-reservation')
      .dateRangePicker({
        autoClose: true,
        format: date_format,
        startOfWeek: start_of_week,
        language,
        startDate: new dayjs().format(date_format),
        singleDate: true,
        extraClass: 'margin-negative',
        singleMonth: true,
        showTopbar: false,
      })
      .on('datepicker-first-date-selected', function (ev, picker) {
        $(this).val(dayjs(picker.time).format(date_format))
      })
      .on('datepicker-open', function () {
        document.dispatchEvent(DATA_RANGE_PICKER_OPENED_EVENT)

        $('.date-picker-wrapper').css({
          left: $(this).offset().left - 5,
          width: 'auto',
        })
        setTimeout(function () {
          $('.real-today').trigger('focus')
        }, 500)
      })
      .on('datepicker-close', function () {
        document.dispatchEvent(DATA_RANGE_PICKER_CLOSED_EVENT)
      })

    var toggleInputs = document.querySelectorAll('.js-toggle-inputs')

    toggleInputs.forEach(function (element) {
      element.removeEventListener('click', toggleInputsHandler)
      element.addEventListener('click', toggleInputsHandler)
    })
  }

  // Form validation init
  IB.forms.initValidation($wrapper.find('form'), true)
}

window.IB.forms.reservation_management = {
  init,
}

const $js_reservation_management_form = $('.js-reservation-management-form')
if ($js_reservation_management_form.length) IB.forms.reservation_management.init($js_reservation_management_form)
