import _debounce from 'lodash/debounce';
import { isVideoPlaying } from '../../utils/videoUtils.js';

class simpleSlider {
  constructor({ wrapper, slides, autoPlayTimeout = 6000, loop = true, navigation = {}, touchMoveEnabled = false, swipeThreshold = 100 }) {
    this.wrapper = wrapper;
    this.slides = slides;
    this.autoPlayTimeout = autoPlayTimeout;
    this.loop = loop; // if true returns to first slide after last slide
    this.navigation = navigation;
    this.touchMoveEnabled = touchMoveEnabled;
    // Set how far the user must swipe before it is considered a swipe. Example 50: Swipe at least 50px
    this.swipeThreshold = swipeThreshold;

    this.isStarted = false;
    this.isPlaying = false;
    this.activeIndex = 0;
    this.currentTimeout;
    this.allSlidesContentLoaded = this.wrapper.querySelectorAll('.js-lazy-load:not(.is-loaded)').length === 0;
    this.touchStartCoord = 0;

    this.addEventListener = this.wrapper.addEventListener.bind(this.wrapper);

    this._start();
  }

  // Private Methods
  _start() {
    if (this.isStarted || this.isPlaying) return;
    this.activeIndex = 0;
    this.isStarted = true;

    const firstSlide = this.slides[0];

    firstSlide.classList.add('active');

    // Preload next/prev slide content
    this._preloadNext();
    this._preloadPrev();

    // Handles video reproduction if needed
    this._handleVideoPlay({ currentIndex: this.activeIndex });

    // Event listeners

    // Prev button click
    Boolean(this.navigation?.prev) &&
      document.querySelector(this.navigation.prev)?.addEventListener('click', () => {
        this.prev();
      });

    // Next button click
    Boolean(this.navigation?.next) &&
      document.querySelector(this.navigation.next)?.addEventListener('click', () => {
        this.next();
      });

    // Touch move
    this.touchMoveEnabled && this.wrapper.addEventListener('touchstart', this._handleTouchStart.bind(this), { passive: false });
    this.touchMoveEnabled && this.wrapper.addEventListener('touchmove', _debounce(this._handleTouchMove.bind(this), 100), { passive: false });
  }

  _sliderLoop() {
    if (!this.isPlaying) return;
    const currentSlide = this.slides[this.activeIndex];
    const video = this._getVideo(currentSlide);
    this._setActiveSlide(this.activeIndex);

    // Preload next slide content
    !this.allSlidesContentLoaded && this._preloadNext();

    if (!!video) {
      this._continueOnVideoReady(video, () => {
        video.currentTime = 0;
        video.play();

        // Next iteration
        video.onended = () => {
          this._nextIteration();
        };
      });
    } else {
      // Next iteration
      this.currentTimeout = window.setTimeout(() => {
        this._nextIteration();
      }, this.autoPlayTimeout);
    }
  }

  _nextIteration() {
    if (!this.loop && this.slides.length === this.activeIndex + 1) return;
    this.next();
    this._sliderLoop();
  }

  _setActiveSlide(index) {
    if (this.activeIndex === index) return;

    const lastIndex = this.activeIndex;
    this.activeIndex = index;

    // Remove active classes
    for (var i = 0; i < this.slides.length; ++i) {
      this.slides[i].classList.remove('active');
    }

    // Set current active slider
    this.slides[index].classList.add('active');

    // Handles video reproduction if needed
    this._handleVideoPlay({ lastIndex, currentIndex: index });

    this.dispatch('slider:change', this.activeIndex);
  }

  _getVideo(slide) {
    return slide.tagName === 'VIDEO' ? slide : slide.querySelector('video');
  }

  _startAutoplay() {
    this.isPlaying = true;
    this._sliderLoop();
    this.dispatch('slider:start');
  }

  _preloadNext() {
    if ((!this.loop && this.slides.length === this.activeIndex + 1) || this.allSlidesContentLoaded) return;
    const nextIndex = this._getNextIndex();
    if (nextIndex === this.activeIndex) return;
    this._preload(nextIndex);
  }

  _preloadPrev() {
    if ((!this.loop && this.activeIndex === 0) || this.allSlidesContentLoaded) return;
    const prevIndex = this._getPrevIndex();
    if (prevIndex === this.activeIndex) return;
    this._preload(prevIndex);
  }

  _preload(index) {
    if (this.allSlidesContentLoaded) return;

    const itemToLoad = this.slides[index].querySelector('.js-lazy-load:not(.is-loaded):not(.is-loading)');

    // Return if already loaded
    if (!itemToLoad) return;

    itemToLoad.classList.add('is-loading');

    const isVideo = itemToLoad.tagName === 'VIDEO';
    const srcAttrName = isVideo ? 'src' : 'srcset';

    itemToLoad.querySelectorAll('source').forEach(source => {
      source.setAttribute(srcAttrName, source.dataset[srcAttrName]);
      itemToLoad.classList.remove('is-loading');
      itemToLoad.classList.add('is-loaded');
    });
    isVideo && !isVideoPlaying(itemToLoad) && itemToLoad.load();

    this.allSlidesContentLoaded = this.wrapper.querySelectorAll('.js-lazy-load:not(.is-loaded):not(.is-loading)').length === 0;
  }

  _continueOnVideoReady(video, callback) {
    if (video.readyState >= video.HAVE_FUTURE_DATA) {
      callback();
    } else {
      video.addEventListener('canplay', callback(), false);
    }
  }

  _getNextIndex() {
    if (this.slides.length === this.activeIndex + 1) {
      return this.loop ? 0 : this.activeIndex;
    } else {
      return this.activeIndex + 1;
    }
  }

  _getPrevIndex() {
    if (this.activeIndex === 0) {
      return this.loop ? this.slides.length - 1 : this.activeIndex;
    } else {
      return this.activeIndex - 1;
    }
  }

  _handleVideoPlay({ lastIndex, currentIndex }) {
    const lastSlide = lastIndex || lastIndex === 0 ? this.slides[lastIndex] : null;
    const currentSlide = currentIndex || currentIndex === 0 ? this.slides[currentIndex] : null;

    // If there's video in last slide pauses it
    if (lastSlide) {
      const lastSlideVideo = this._getVideo(lastSlide);
      if (!!lastSlideVideo) {
        this._continueOnVideoReady(lastSlideVideo, () => {
          lastSlideVideo.pause();
        });
      }
    }

    // If there's video in current slide plays it
    if (currentSlide) {
      const currentSlideVideo = this._getVideo(currentSlide);
      if (!!currentSlideVideo) {
        this._continueOnVideoReady(currentSlideVideo, () => {
          currentSlideVideo.currentTime = 0;
          !isVideoPlaying(currentSlideVideo) && currentSlideVideo.play();
        });
      }
    }
  }

  // Touch events
  _handleTouchStart(event) {
    this.touchStartCoord = event.touches[0].clientX;
  }

  _handleTouchMove(event) {
    let currentCoord = event.touches[0].clientX;
    const offset = Math.abs(this.touchStartCoord - currentCoord);

    if (offset < this.swipeThreshold) return;

    event.preventDefault();

    this.touchStartCoord < currentCoord ? this.prev() : this.next();

    currentCoord = 0;
    return false;
  }

  // Public Methods
  play() {
    if (this.isPlaying) return;

    if (!this.isStarted) {
      this.start();
    }

    this._startAutoplay();
    this.dispatch('slider:play');
  }

  pause() {
    if (!this.isPlaying) return;
    clearTimeout(this.currentTimeout);
    this.isPlaying = false;
    this.dispatch('slider:pause');
  }

  stop() {
    this.pause();
    this._setActiveSlide(0);
    this.dispatch('slider:stop');
  }

  goTo(index) {
    if (index >= this.slides.length) return;

    this.isPlaying && this.stop();
    this._preload(index);
    this._setActiveSlide(index);

    // Preload slides content
    this._preloadPrev();
    this._preloadNext();
  }

  next() {
    if (!this.loop && this.slides.length === this.activeIndex + 1) return;
    const nextIndex = this._getNextIndex();
    if (nextIndex === this.activeIndex) return;
    this._setActiveSlide(nextIndex);
    this._preloadNext();
  }

  prev() {
    if (!this.loop && this.activeIndex === 0) return;
    const prevIndex = this._getPrevIndex();
    if (prevIndex === this.activeIndex) return;
    this._setActiveSlide(prevIndex);
    this._preloadPrev();
  }

  dispatch(event, detail) {
    return this.wrapper.dispatchEvent(
      new window.CustomEvent(event, {
        detail: detail,
      })
    );
  }
}

export default simpleSlider;
