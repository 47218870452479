// PY05355, Fastbooking REB - Ficha de hotel 2023
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { currentDevice } from '../../core/utils/currentDevice'
import LoadStyles from '../src/mixins/LoadStyles/LoadStyles'

import fastbooking from '../src/components/Fastbooking/Fastbooking'

const BASE_ID_SELECTOR = '#vue-fastbooking'

function init() {
  if (currentDevice.isMobile || document.querySelector(BASE_ID_SELECTOR) === null) {
    return
  }

  const fb = {
    // Root components must be imported in lowercase
    components: {
      fastbooking,
    },
  }

  const app = createApp(fb)

  app.use(createPinia())

  // i18n global variable
  app.provide('i18n', JSON.parse(document.querySelector(BASE_ID_SELECTOR).dataset.i18n))

  /**
   * TODO: Use Composables instead of Mixins
   * https://vuejs.org/api/options-composition.html#mixins
   * https://vuejs.org/guide/reusability/composables.html
   */
  app.mixin(LoadStyles)
  app.mount(BASE_ID_SELECTOR)
}

const Fastbooking = {
  init,
}

Fastbooking.init()

export default Fastbooking
