import { initializeUtagEvents } from './hotel_collapsible.analytics'

const BLOCK_WOC = '.hotel-woc'
const BLOCK_WOC_HEADER = '.hotel-woc__header'

function initCollapsibles() {
  const collapsibles = document.querySelectorAll('.hotel-collapsible')
  collapsibles.forEach(collapsible => {
    const hasWOC = collapsible.querySelector(BLOCK_WOC) ? true : false

    const header = collapsible.querySelector('.hotel-collapsible__summary-header')
    const button = collapsible.querySelector('.hotel-collapsible__summary-button')
    const summary = collapsible.querySelector('.hotel-collapsible__summary')
    const contentHeader = collapsible.querySelector('.hotel-collapsible__content-header')

    if (hasWOC) {
      // add woc class modifier
      collapsible.classList.add('hotel-collapsible--woc')

      // copy woc header to collapsible header
      const wocHeader = collapsible.querySelector(BLOCK_WOC_HEADER)
      header.innerHTML = wocHeader.outerHTML

      // load header image
      const headerImage = header.querySelector('.hotel-woc__header-image .image')
      headerImage
        .querySelectorAll('source')
        .forEach(source => source.setAttribute('srcset', source.getAttribute('data-srcset')))
      headerImage.classList.add('loaded')

      // remove woc header
      wocHeader.remove()
      contentHeader.remove()
    }

    summary.addEventListener('click', () => {
      button.classList.toggle('hotel-collapsible__summary-button--active')
      initializeUtagEvents(button)
      if (!hasWOC) {
        header?.classList.toggle('hotel-collapsible__summary-header--active')
        contentHeader?.classList.toggle('hidden')
      }
    })
  })
}

initCollapsibles()
