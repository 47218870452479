<template>
  <div ref="fastbooking" class="reb-fastbooking">
    <header class="reb-fastbooking__header">
      <h1 class="reb-fastbooking__hotel">
        {{ props.hotel.title }}
      </h1>
      <div class="reb-fastbooking__stars" v-html="props.hotel.stars" />
    </header>
    <div class="reb-fastbooking__country">
      {{ props.hotel.country }}
    </div>
    <div class="reb-fastbooking__flight t-link" @click="openFlightTab()">
      {{ i18n.add_flight }}
    </div>
    <FastbookingForm
      class="reb-fastbooking__form"
      @open-fastbooking="openFastbooking()"
      @submit-fastbooking="submitFastbooking()"
    />
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

import { UPDATE_FASTBOOKING_STATE_EVENT } from '../../../../shared/fastbooking/fastbooking.state'
import { initTestRebHeader } from '../../../../utils/test_reb_header'
import { useFastbookingStore } from '../../store/fastbooking'
import FastbookingForm from '../FastbookingForm/FastbookingForm.vue'

const FASTBOOKING_STATE_KEY = 'fastbooking_state'

const i18n = inject('i18n')

/**
 * Props
 */
const props = defineProps({
  hotel: {
    type: Object,
    required: true,
  },
})

/**
 * Refs
 */
const fastbooking = ref(null)

/**
 * Store
 */
const fbStore = useFastbookingStore()

/**
 * Update fastbooking state from local storage
 */
const updateFastbookingState = () => {
  const fastbookingState = JSON.parse(localStorage.getItem(FASTBOOKING_STATE_KEY))

  fbStore.updateState(fastbookingState)
}

/**
 * Init local storage listener for fastbooking state
 */
const initStorageListener = () => {
  document.addEventListener(UPDATE_FASTBOOKING_STATE_EVENT, updateFastbookingState)
}

/**
 * Open original fastbooking
 *
 * Current implementation can only open fastbooking with dates opened
 */
const openFastbooking = () => {
  const header = fastbooking.value.closest('.js-hotel-header-container')
  window.scroll({ top: header.scrollHeight, behavior: 'smooth' })
  setTimeout(() => {
    document.querySelector('.js-header-sticky-hotel .btn-floating-fastbooking').click()
  }, 1000)
}

/**
 * Open flight tab in original fastbooking
 */
const openFlightTab = () => {
  const fastbooking = !initTestRebHeader
    ? document.querySelector('.js-fastbooking-home')
    : document.querySelector('.js-reb-header')
  fastbooking.querySelector('.js-fastbooking-tabs .js-flight-and-hotels-tab').click()
  openFastbooking()
}

/**
 * This method is intended to be the submit of the fastbooking
 * For now, due to limitations of the legacy fastbooking code, it only opens the fastbooking
 */
const submitFastbooking = () => {
  openFastbooking()
}

onMounted(() => {
  updateFastbookingState()

  initStorageListener()
})
</script>
<style scoped lang="scss">
@use '../../../../base_css/abstracts/abstracts' as *;

.reb-fastbooking {
  display: grid;
  grid-gap: 8px;
  grid-template:
    'header header'
    'country flight'
    'form form' / 1fr 1fr;
  width: 512px;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--c_white);
  color: var(--c_black_grey_700);
  @include link-styles;

  &__header {
    display: grid;
    grid-area: header;
    grid-gap: 0 4px;
    grid-template-rows: 15px 20px;
    grid-template-columns: auto 1fr;
  }

  &__hotel {
    @include fontSize(18);
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    align-self: end;
    font-family: var(--title-font);
  }

  &__stars {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    align-self: start;
  }

  &__country {
    @include fontSize(14);
    grid-area: country;
  }

  &__flight {
    @include fontSize(14);
    cursor: pointer;
    grid-area: flight;
    justify-self: end;
  }

  &__form {
    grid-area: form;
  }
}
</style>
<style lang="scss">
.fb {
  .rating-stars {
    &::before {
      color: inherit;
    }
  }
}
</style>
