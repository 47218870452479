import { sendUtagEvent } from '../../core/analytics/utag_events'

export function initializeUtagEvents() {
  const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined
  const fhiPageType = document.querySelector('html').dataset.fhiTest

  document.querySelectorAll('.utag-hotel-header-thumbnail').forEach(element => {
    element.addEventListener('click', () => {
      const data = {
        event_name: 'hotel_multimedia',
        event_cat: 'hotel information',
        event_act: 'see photos and videos',
        event_lbl: 'photos',
        event_purpose: 'inspiration',
        event_structure: 'popup',
      }

      if (!isFhiPage) {
        sendUtagEvent({ data: { ...data } })
      } else {
        sendUtagEvent({ data: { ...data, page_variation: fhiPageType } })
      }
    })
  })

  document.querySelector('.utag-info-photos')?.addEventListener('click', () => {
    const data = {
      event_name: 'hotel_multimedia',
      event_cat: 'hotel information',
      event_act: 'see photos and videos',
      event_lbl: 'header',
      event_purpose: 'inspiration',
      event_structure: 'header',
    }

    if (!isFhiPage) {
      sendUtagEvent({ data: { ...data } })
    } else {
      sendUtagEvent({ data: { ...data, page_variation: fhiPageType } })
    }
  })
}
