// PY05355, Fastbooking REB - Ficha de hotel 2023
import '../../../src/vue/fastbooking/vue_fastbooking'

import { currentDevice } from '../../core/utils/currentDevice'
import * as SliderNav from '../../elements/scrollsnap_slider/scrollsnap_slider_nav'
import simpleSlider from '../../shared/simpleSlider/simpleSlider.js'
import getRealMarketContent, { dispatchRealMarketContentUpdatedEvent } from '../../utils/get_real_market_content'
import inViewport from '../../utils/inViewport'
import { scrollToElementWithHeader } from '../../utils/scroll_to_element'
import { initializeUtagEvents } from '../hotel_header/hotel_header.analytics'

// Replace CTAs with real market html content
function updateCtaWithRealMarketContent() {
  const blockSelector = '.js-hotel-header-container'
  const mainContainer = document.querySelector(blockSelector)
  const blockId = mainContainer?.dataset.blockId
  const constrainedViewId = mainContainer?.dataset.constrainedViewId

  if (blockId && constrainedViewId) {
    getRealMarketContent({ blockId, constrainedViewId })
      .then(response => {
        // Desktop replacement
        const stickyContainer = document.querySelector('.js-header-sticky-hotel')
        const desktopCta = stickyContainer.querySelector('.js-download-button')
        if (desktopCta) {
          desktopCta.outerHTML = response.html_replacement
        }

        // Mobile replacement: same as desktop but without main div container and with different classes
        const mobileCta = mainContainer.querySelectorAll('.js-fastbooking-btn-price:not(.hidden)')
        function parseHtmlButtonToMobile(html) {
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = html

          // add mobile classes
          const button = tempDiv.querySelector('.btn-floating-fastbooking')
          button?.classList.add('js-cta-btn', 'js-fastbooking-btn-price')

          // Remove room/night legend
          tempDiv.querySelector('.js-hint-cnt')?.remove()

          // Remove duplicated string
          tempDiv.querySelector('.js-stays-per')?.remove()

          // return html without main div container, in mobile only btn-floating-fastbooking is needed
          const mainWrapper = tempDiv.querySelector('.js-download-button')
          return mainWrapper.innerHTML
        }

        if (mobileCta.length) {
          mobileCta.forEach(element => {
            element.outerHTML = parseHtmlButtonToMobile(response.html_replacement)
          })
        }

        // Reinit prices after replacement
        const hotelHeadersSelector = `${blockSelector}, .js-header-sticky-hotel`
        IB.currencyForm.forceUpdate($(hotelHeadersSelector))

        dispatchRealMarketContentUpdatedEvent({ blockSelector: hotelHeadersSelector, blockName: 'hotel-header' })
      })
      .catch(error => {
        // console.warn(error);
      })
  }
}

;(function () {
  if (document.querySelector('.js-hotel-header') === null) return

  const block_selector = '.hotel-header'
  const block = document.querySelector(`${block_selector}`)
  initializeUtagEvents()

  // Giving the browser time to style the css slider
  // so it hides the slides but the first one
  // and the observer doesn't detect them (buggy behaviors)
  const timeToHideOtherSlides = 1500

  // Common video elements
  const video = document.querySelector('.header-top .header-image')
  const video_item = video.querySelector('.video-content')

  // Common pictures
  const picture = document.querySelector('.header-image')

  // Common videos
  const videos_arr = video.dataset.videos.split(' | ')

  // scroll down button
  const $js_scrolldown = $(block).find('.js-scrolldown')
  $js_scrolldown &&
    $js_scrolldown.on('click', function () {
      scrollToElementWithHeader(document.querySelector('.js-masonry-gallery'))
    })

  if (currentDevice.isMobile) {
    // circle button in bottom left corner which opens gallery
    const header_actions = block.querySelector(`.hotel-abouts .header-actions`)

    // setNumberImagesShowMoreMobile
    if (document.querySelector('.gallery-container')) {
      const totalGalleryImages = parseInt(document.querySelector('a[data-items]')?.dataset.items)
      const numVisibleImages = parseInt(document.querySelector('.gallery-container')?.dataset.numVisibleImages)
      const showMoreNode = document.querySelector('.show-more')
      showMoreNode.innerHTML =
        totalGalleryImages - numVisibleImages > 0 ? `+ ${totalGalleryImages - numVisibleImages}` : ''
    }

    // Hide video section in mobile
    if (video_item) {
      video_item.style.display = 'none'
    }

    // request idle callback too fast to use here
    setTimeout(function () {
      SliderNav.init(
        document.querySelector('.hotel-header .ss-slider'),
        document.querySelector('.hotel-header .ss-slider-nav'),
        function (slide) {
          document.querySelectorAll('.hotel-header .ss-slider picture').forEach((picture, index) => {
            if (index > 0) {
              picture.style.display = 'block'
            }
          })
          slide && slide.classList.contains('last')
            ? header_actions.classList.add('is-hidden')
            : header_actions.classList.remove('is-hidden')
        }
      )
    }, timeToHideOtherSlides)

    // Replace default images with gif in some cases
    const videoSliderContainer = video.querySelector('.ss-slider--container')
    if (videoSliderContainer) {
      videoSliderContainer.querySelectorAll('.ss-slide.js-slide-element').forEach((item, index) => {
        const is_valid_videos_arr = videos_arr && videos_arr[index]
        if (is_valid_videos_arr && videos_arr[index] !== 'No video' && videos_arr[index] !== '') {
          let value = ''
          value =
            index ===
            video.querySelector('.ss-slider--container').querySelectorAll('.ss-slide.js-slide-element').length - 1
              ? videos_arr[0]
              : videos_arr[index]
          item.querySelector('img').setAttribute('src', value)
          if (item.querySelectorAll('source').length > 0) {
            item.querySelectorAll('source').forEach(subitem => {
              subitem.setAttribute('data-srcset', value)
            })
          }
        }
      })
    }
  } else {
    // only one image available, no need to keep executing script
    if (!block.querySelectorAll('.js-slide-item').length) {
      if (video_item) video_item.loop = true
    } else {
      // Simple slider init
      const $sliderWrapper = document.querySelector('.js-ss-slider')

      if (!$sliderWrapper) return

      const $sliderSlides = $sliderWrapper.querySelectorAll('.js-slide-item')

      if ($sliderSlides.length > 1) {
        const $sliderNav = document.querySelector('.js-ss-slider-nav')
        const $sliderNavItems = $sliderNav.querySelectorAll('[data-slide-index]')

        const slider = new simpleSlider({
          wrapper: $sliderWrapper,
          slides: $sliderSlides,
          autoPlayTimeout: 6000,
        })

        slider.addEventListener('slider:change', function (event) {
          const activeIndex = event.detail
          // Update nav
          $sliderNav.querySelector('[data-slide-index].active').classList.remove('active')
          $sliderNav.querySelector(`[data-slide-index="${activeIndex}"]`).classList.add('active')
        })

        // Play slider when entering viewport and Pause when leaving
        const sliderIntersectionObserver = inViewport(
          $sliderWrapper,
          element => {
            if (element.isIntersecting) {
              slider.play()
            } else {
              slider.pause()
            }
          },
          {
            threshold: 0.3,
          }
        )

        for (let i = 0; i < $sliderNavItems.length; i++) {
          $sliderNavItems[i].addEventListener('click', event => {
            const newSlideActiveIndex = event.target.dataset?.slideIndex

            if (!newSlideActiveIndex) return

            slider.goTo(parseInt(newSlideActiveIndex))

            // Autoplay is stopped, so unobserve intersection to prevent a new autoplay
            sliderIntersectionObserver.unobserve($sliderWrapper)
          })
        }
      }
    }
  }

  updateCtaWithRealMarketContent()
})()
