// PY05355, Fastbooking REB - Ficha de hotel 2023
import '../fastbooking/vue_fastbooking'

import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { initTestRebHeader } from '../../utils/test_reb_header'
import LoadStyles from '../src/mixins/LoadStyles/LoadStyles'

import hotelheaderlite from '../src/components/HotelHeaderLite/HotelHeaderLite'

const BASE_ID_SELECTOR = '#vue-hotel-header-lite'
const WRAPPER_SELECTOR = '.js-hotel-reb-header'

function init() {
  if (!document.querySelector(BASE_ID_SELECTOR)) return

  if (initTestRebHeader) {
    document.querySelector(WRAPPER_SELECTOR).classList.add('hotel-header-lite-wrapper--test-reb-header')
  }

  const app = createApp({
    el: BASE_ID_SELECTOR,
    // Root components must be imported in lowercase
    components: {
      hotelheaderlite,
    },
  })

  app.use(createPinia())

  // i18n global variable
  app.provide('i18n', JSON.parse(document.querySelector(BASE_ID_SELECTOR).dataset.i18n))

  /**
   * TODO: Use Composables instead of Mixins
   * https://vuejs.org/api/options-composition.html#mixins
   * https://vuejs.org/guide/reusability/composables.html
   */
  app.mixin(LoadStyles)
  app.mount(BASE_ID_SELECTOR)
}

init()
