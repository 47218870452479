<template>
  <div class="hotel-header-lite">
    <div class="slider js-header-image swiper-container" @click="openGalleryOnClick">
      <div class="slider__content swiper-wrapper" :style="`transform:translateX(-${currentSlide * 100}%)`" >
        <slot name="slider_content"/>
      </div>
      <button @click="prevSlide" v-if="nSlides !== 1 && ( isCyclic  || (currentSlide !== 0 && !isCyclic))" class="slider__controls__prev slider__controls__button"></button>
      <button @click="nextSlide"
        v-if="(nSlides !== 1 && (isCyclic || isStartLink )) || (!isLastSlide && isStartEnd) || (nSlides == 1  && isStartLink)"
        :class="(isStartLink && isLastSlide)? 'slider__controls__next--gallery':'slider__controls__next--active'"
        class="slider__controls__next slider__controls__button">
          <span class="slider__controls__next__text"
          :class="(isStartLink && isLastSlide)? '':'hidden'" >{{ next_text }}</span>
      </button>
      <div class="slider__controls__bullets">
        <div v-for="(slide, index) in nSlides" :key="index" @click="moveToSlide(slide-1, $event)" :class="`slider__controls__bullets__bullet ${currentSlide === index ? 'slider__controls__bullets__bullet--active' : ''}`"></div>
        <div v-if="isStartLink" class="slider__controls__bullets__bullet--gallery" @click="openGallery">
          <span class="s s-grid icon"></span>
        </div>
      </div>
    </div>
    <div
      v-if="!isMobile && use_new_fb == 'true'"
      class="fastbooking-wrapper wrapper"
    >
      <Fastbooking :hotel="hotel"></Fastbooking>
    </div>

    <div v-if="isMobile" class="hotel-header-lite__mobile-title">
        <span v-html="hotel.stars"></span>
        <h1 class="t-h1"> {{hotel.title}} </h1>
        <span class="hotel-header-lite__mobile-title__zone">
          {{hotel.zone_title}}
        </span>
    </div>

    <div v-if="isMobile" class="hotel-header-lite__mobile-buttons">
      <a class="button-gallery utag-info-photos js-see-all js-open-gallery" @click="openGallery">
        <span class="s s-camera s-m"></span>
        <p class="link-text">fotos y vídeos</p>
      </a>
      <slot name="fastbooking_button"/>
  </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'
import{ sendUtagEvent } from '../../../../core/analytics/utag_events.js'
import { currentDevice } from '../../../../core/utils/currentDevice';
import Fastbooking from '../Fastbooking/Fastbooking.vue';
import { openGallery as openGalleryOriginal } from '../../../../blocks/hotel_gallery/hotel_gallery';

export default {
  name: 'HotelHeaderLite',
  props: {
    slides: {
      type: String,
      required: true
    },
    next_text: {
      type: String,
      required: true
    },
    carrusel_type: {
      type: String,
      required: true
    },
    use_new_fb: {
      type: String,
      required: true
    },
    hotel: {
      type: Object,
      required: true
    },
  },
  components: {
    Fastbooking,
  },
  data: () => ({
    currentSlide: 0,
    isLastSlide: false,
    isCyclic: false,
    isStartEnd: false,
    isStartLink: false,
  }),
  computed: {
    isMobile() {
      return currentDevice.isMobile;
    },
    nSlides() {
      return parseInt(this.slides);
    },
  },
  watch: {
    currentSlide() {
      this.isLastSlide = this.currentSlide == (this.nSlides - 1);
    },
  },
  created() {
    this.isLastSlide = this.nSlides == 1;
    this.isCyclic = this.carrusel_type == "cyclic";
    this.isStartEnd = this.carrusel_type == "start_end";
    this.isStartLink = this.carrusel_type == "start_link";
  },
  mounted() {
    if(this.isMobile){
      Swiper.use([Pagination]);
      new Swiper('.hotel-header-lite .slider', {
        direction: 'horizontal',
        loop: false,
        navigation: false,
        touch: { enabled: true},
        pagination: {
          el: '.slider__controls__bullets',
          bulletClass: 'slider__controls__bullets__bullet',
          bulletActiveClass: 'slider__controls__bullets__bullet--active',
          clickable: true,
        },
      });
    }
  },
  methods: {
    nextSlide(event) {
      event.preventDefault()
      event.stopPropagation()
      if (this.currentSlide < this.nSlides - 1) {
        this.currentSlide++;
        this.sendEvent(false)
      } else {
        if (this.isCyclic) {
          this.currentSlide = 0;
          this.sendEvent(false)
        } else if (this.isStartLink) {
          this.openGallery(event);
        }
      }
    },
    prevSlide(event) {
      event.preventDefault()
      event.stopPropagation()
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        if (this.isCyclic) {
          this.currentSlide = this.nSlides - 1;
        }
      }
      this.sendEvent(false)
    },
    openGalleryOnClick(event) {
      event.preventDefault()
      event.stopPropagation()

      if(this.nSlides == 1){
        openGalleryOriginal()
      }
    },
    openGallery(event) {
      event.preventDefault()
      event.stopPropagation()

      openGalleryOriginal()
      this.sendEvent(true)
    },
    moveToSlide(index, event) {
      event.preventDefault()
      event.stopPropagation()
      this.currentSlide = index;
    },
    sendEvent(isGallery){
      const event_lbl = isGallery ? 'go to gallery' : 'header'
      sendUtagEvent({
          data: {
            event_name: 'hotel_multimedia',
            event_cat: 'hotel information',
            event_act: 'see photos and videos',
            event_lbl,
            event_purpose: 'inspiration',
            event_structure: 'header',
          },
        })
    }
  },
}
</script>
