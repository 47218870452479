<template>
  <button class="fastbooking-button btn-primary">
    <span>{{ i18n.availability }}</span>
  </button>
</template>

<script setup>
import { inject } from 'vue'

const i18n = inject('i18n')
</script>
<style scoped lang="scss">
.fastbooking-button {
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 8px;
  background-color: var(--fasbooking-submit-background);
  color: var(--fasbooking-submit-label-color);
  font-weight: 700;
  font-size: 14px;
  text-align: center;

  &:hover {
    background-color: var(--fasbooking-submit-background-hover);
    color: var(--fasbooking-submit-label-color-hover);
  }
}
</style>
