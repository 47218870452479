import { defineStore } from 'pinia'

const initialState = {
  dates: {
    check_in: '',
    check_out: '',
  },
  guests: {
    adults: 0,
    children: 0,
    total: 0,
  },
  rooms: 0,
  promocode: '',
}

export const useFastbookingStore = defineStore('fastbooking', {
  state: () => {
    return { ...initialState }
  },
  actions: {
    updateState(fastbooking_data) {
      this.dates = fastbooking_data.dates || initialState.dates
      this.guests = fastbooking_data.people || initialState.guests
      this.rooms = fastbooking_data.rooms_info ? Object.values(fastbooking_data.rooms_info).length : initialState.rooms
      this.promocode = fastbooking_data.promocode?.isValid ? fastbooking_data.promocode : initialState.promocode
    },
  },
})
