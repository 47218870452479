<template>
  <div class="fbForm">
    <div class="fb-form__fields">
      <FastbookingField
        class="fb-form__field"
        icon="s-calendar"
        :placeholder="i18n.dates"
        :value="dates()"
        @click="$emit('openFastbooking')"
      />
      <FastbookingField
        class="fb-form__field"
        icon="s-person"
        :placeholder="i18n.guests"
        :value="guests()"
        @click="$emit('openFastbooking')"
      />
      <FastbookingField
        class="fb-form__field"
        icon="s-double-bed"
        :placeholder="i18n.rooms"
        :value="rooms()"
        @click="$emit('openFastbooking')"
      />
      <FastbookingField
        class="fb-form__field"
        type="promocode"
        icon="s-percentage"
        :placeholder="i18n.discount"
        :value="promocode()"
        @click="$emit('openFastbooking')"
      />
    </div>
    <FastbookingButton @click="$emit('submitFastbooking')" />
  </div>
</template>

<script setup>
import { inject } from 'vue'

import { useFastbookingStore } from '../../store/fastbooking'
import FastbookingButton from '../FastbookingButton/FastbookingButton.vue'
import FastbookingField from '../FastbookingField/FastbookingField.vue'

const i18n = inject('i18n')

const fbStore = useFastbookingStore()

/**
 * Emits
 */
defineEmits(['openFastbooking', 'submitFastbooking'])

/**
 * Getters for fastbooking inputs
 */
const dates = () => {
  const { check_in, check_out } = fbStore.dates
  if (!check_in && !check_out) {
    return ''
  }
  return `${check_in} ${check_out}`
}

const guests = () => {
  const { total } = fbStore.guests
  return total === 0 ? '' : total
}

const rooms = () => {
  return fbStore.rooms === 0 ? '' : fbStore.rooms
}

const promocode = function () {
  return fbStore.promocode ? `${i18n.discount_applied} (1)` : ''
}
</script>
<style scoped lang="scss">
.fb-form {
  &__fields {
    display: grid;
    grid-template-columns:
      minmax(max-content, 1fr)
      minmax(70px, max-content)
      minmax(70px, max-content)
      1fr;
    padding: 8px;
    overflow: hidden;
    border: 1px solid var(--c_black_grey_700);
  }

  &__field {
    padding: 0 16px;
    font-weight: 700;

    &:hover {
      border-radius: var(--border-radius);
      background: var(--interactive-background-hover);
      color: var(--interactive-text-hover);
    }

    &:last-child {
      border-right: 0;
    }
  }
}
</style>
