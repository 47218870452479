<template>
  <div class="fb-field" :class="`fb-field--${type}`">
    <span class="fb-field__icon" :class="iconClass" />
    <span class="fb-field__input">
      {{ value() }}
    </span>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  value: {
    type: [String, Number],
    required: true,
  },
  type: {
    type: String,
    default: '',
    required: false,
  },
})

const iconClass = `s ${props.icon}`

const value = () => {
  return props.value || props.placeholder
}
</script>
<style scoped lang="scss">
@use '../../../../base_css/abstracts/abstracts' as *;

.fb-field {
  $self: &;

  @include fontSize(12);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c_black_grey_700);

  &--promocode {
    #{$self}__input {
      white-space: nowrap;
    }
  }

  &__icon {
    margin-right: 8px;

    &::before {
      @include fontSize(16);
    }
  }

  &__input {
    max-width: 70px;

    &::placeholder {
      color: inherit;
    }
  }
}
</style>
